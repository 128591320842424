<template>
  <div>
    <v-dialog
      v-model="exportacaoAsyncDialog"
      scrollable
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-file-delimited-outline</v-icon>
          Exportar Relatório
          <v-btn
            @click="exportacaoAsyncDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Requisição entrou na fila para processamento.</p>
          <p>Para ver o progresso e baixar o relatório, veja a fila.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="exportacaoAsyncDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon> Fechar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="verFila()"
          >
            Ver fila
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rastrearColaboradorId"
      scrollable
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="pb-1 pr-8">
          <h3>Rastreio</h3>
          <v-btn
            @click="
              rastrearColaboradorId = null;
              desAnteriores = [];
            "
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 form_container">
          <table-v
            v-if="desAnteriores.length > 0"
            :cols="rastreioCols"
            :opts="opts"
            :rows="desAnteriores"
            :hasFilter="false"
            :disableHover="true"
            height="528px"
          ></table-v>
          <div v-else>O funcionário não possui De-para.</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            dark
            depressed
            @click="
              rastrearColaboradorId = null;
              desAnteriores = [];
            "
          >
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <master-detail
      descriptionProperty="nome"
      :actionBarButtons="actionBarButtons"
      :canEdit="false"
      :canDelete="!isClient"
      :cols="cols"
      :contextOptions="contextOptions"
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :hasNewButton="false"
      :opts="opts"
      :resourceUrl="resourceUrl"
      :serverPagination="true"
      @contextmenu="contextHandler"
    >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div class="table-v-action-button mr-3" v-bind="attrs" v-on="on">
            <v-icon>mdi-file-delimited-outline</v-icon>
            Exportações Assíncronas
          </div>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title
              class="table-v-action-button"
              @click="exportCsv(false)"
            >
              <v-icon>mdi-file-delimited</v-icon>
              para conferência
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              class="table-v-action-button"
              @click="exportCsv(true)"
            >
              <v-icon>mdi-file-arrow-up-down</v-icon>
              para alteração
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </master-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "colaboradores",
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
    "table-v": () => import("@/components/table-v.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
    actionBarButtons: function () {
      return this.userHasAccessToAction("create")
        ? [
            {
              text: "Adicionar",
              icon: "mdi-plus-box-outline",
              action: () => {
                this.$router.push({ name: "cadastro-colaborador" });
              },
            },
          ]
        : [];
    },
    contextOptions: function () {
      let disabledRastrear = this.disabledRastrear;
      let options = [
        {
          name: "Folha de Pagamento",
          limit: 1,
          show: this.userHasAccess("Colaboradores.folhaDePagamento.index"),
          cb: (row) => {
            this.$router.push({
              name: "folha-pagamento-colaborador",
              params: { colaboradorId: row.id }
            });
          },
        },
        {
          name: "Alocação de Horas",
          limit: 1,
          show: this.userHasAccess("Timesheet.alocacaoDeHoras.index"),
          cb: (row) => {
            this.$router.push({
              name: "timesheets",
              params: { colaboradorId: row.id },
            });
          },
        },
        {
          name: "Cálculo do Dispêndio",
          limit: 1,
          show: this.userHasAccess("Dispendios.recursosHumanos.index"),
          cb: (row) => {
            this.$router.push({
              name: "dispendio-rh",
              params: { colaboradorId: row.id },
              query: { colaboradorId: row.id },
            });
          },
        },
        {
          name: "sep",
          // Só aparece se houver alguma opção antes e depois da separação
          show: {
            get() {
              return options.slice(0, 3).some(({ show }) => show) && options.slice(4).some(({ show }) => show);
            }
          },
        },
        {
          name: "Editar",
          limit: 1,
          show: this.userHasAccessToAction("update"),
          cb: (row) => {
            const routeData = this.$router.resolve({
              name: "edicao-colaborador",
              params: { colaboradorId: row.id },
            });
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "De->Para",
          limit: 1,
          show: this.userHasAccessToAction("depara"),
          cb: (row) => {
            this.$router.push({
              name: "de-para-colaboradores",
              params: { colaboradorId: row.id },
            });
          },
        },
        {
          name: "Rastrear",
          disabled: disabledRastrear,
          show: this.userHasAccessToAction("depara"),
          cb: (row) => {
            this.apiResource(`/v1/rh/deparas/${row.id}`)
              .get()
              .then((response) => {
                this.desAnteriores = response;
                this.rastrearColaboradorId = row.id;
              });
          },
        },
      ];

      return options.filter(({ show }) => show);
    },
    cols: function () {
      const cols = [
        { key: "id", name: "ID" },
        {
          key: "matricula",
          name: "Matricula",
          type: this.$fieldTypes.TEXT,
          filterable: true,
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          nowrap: true,
          filterable: true,
        },
        {
          key: "cpf",
          name: "CPF",
          type: this.$fieldTypes.CPF,
          nowrap: true,
        },
        {
          key: "cargo",
          name: "Cargo",
          type: this.$fieldTypes.TEXT,
          filterable: true,
        },
        {
          key: "area_setor",
          name: "Área/Setor",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "unidade",
          name: "Unidade",
          filterable: true,
        },
        {
          key: "compUnidade",
          name: "Competência da Unidade",
          type: this.$fieldTypes.MONTH,
        },
        {
          key: "grupo",
          name: "Grupo de Encargos",
          filterable: true,
        },
        {
          key: "dedicacao",
          name: "Dedicação",
          type: this.$fieldTypes.SELECT,
          filterable: true,
          rel: { to: "dedicacao", key: "id", name: "nome" },
        },
        {
          key: "compDedicacao",
          name: "Competência da Dedicação",
          type: this.$fieldTypes.MONTH,
        },
        {
          key: "pesquisador",
          name: "Pesquisador",
          type: this.$fieldTypes.AUTOCOMPLETE,
          filterable: true,
          rel: { to: "pesquisador", key: "id", name: "nome" },
        },
        {
          key: "admissao_em",
          name: "Admitido em",
          type: this.$fieldTypes.DATE,
          align: 1,
        },
        {
          key: "demissao_em",
          name: "Demitido em",
          type: this.$fieldTypes.DATE,
          align: 1,
        },
        {
          key: "admissao_emped",
          name: "Data Admissão como Pesquisador",
          type: this.$fieldTypes.DATE,
        },
        {
          key: "desligamento",
          name: "Data Desligamento como Pesquisador",
          type: this.$fieldTypes.DATE,
        },
        {
          key: "escolaridade",
          name: "Escolaridade LB",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "formacao",
          name: "Curso/Formação LB",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "escolaridadeLI",
          name: "Escolaridade LI",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "formacaoLI",
          name: "Curso/Formação LI",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "jobId",
          name: "ID Importação",
          type: this.$fieldTypes.TEXT,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          align: 1,
        },
      ];

      return cols;
    },
    rastreioCols: function () {
      return [{ key: "nome", name: "Nome" }];
    },
    resourceUrl: function () {
      return `/v1/rh/${this.clientId}/colaboradores`;
    },
    isClient: function () {
      return this.getClient().isClient;
    },
  },
  data: function () {
    return {
      desAnteriores: [],
      disabledRastrear: false,
      exportacaoAsyncDialog: false,
      rastrearColaboradorId: null,
      opts: {
        pesquisador: [
          {
            id: 1,
            nome: "Sim",
          },
          {
            id: 0,
            nome: "Não",
          },
        ],
        dedicacao: [
          {
            id: 0,
            nome: "Nenhum",
          },
          {
            id: 1,
            nome: "Exclusivo",
          },
          {
            id: 2,
            nome: "Parcial",
          },
        ],
        tipo_rh: [
          {
            id: 0,
            nome: "Nenhum",
          },
          {
            id: 1,
            nome: "Direto",
          },
          {
            id: 2,
            nome: "Indireto",
          },
        ],
      },
    };
  },
  methods: {
    confirmDeleteMessage: function ({ nome }) {
      return `Deseja realmente deletar o colaborador <b>"${nome}"</b>?`;
    },
    contextHandler: function (v) {
      this.disabledRastrear = v.deparas_count < 1;
    },
    doLoad: function () {
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "info",
        title: "Carregando...",
        text: "Buscando dados...",
      });

      this.loaded = false;
      this.resource.get().then((a) => {
        this.loaded = true;

        if (!a.error) {
          this.rows = !a.colaboradores ? a : a.colaboradores;
        }
      });
    },
    exportCsv: function (forAlt = false) {
      this.apiResource(`v1/clientes/${this.clientId}/exportacao`)
        .save({
          tipoRelatorio: forAlt ? "colaboradores" : "colaboradores-completo",
        })
        .then(() => {
          this.exportacaoAsyncDialog = true;
        });
    },
    verFila: function () {
      this.$router.push({
        path: "/clientes/dashboard/relatorios-exportados",
      });
    },
  },
};
</script>

<style lang="scss">
.v-data-table__empty-wrapper {
  position: absolute;
  left: 40%;
}
</style>
